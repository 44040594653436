/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import {
  DialogTrigger,
  ActionButton,
  Dialog,
  Heading,
  Content,
  View,
  Text,
  ButtonGroup,
  Button,
} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import { Link } from 'react-router-dom';

interface SyncErrorDialogProps {
  errorCount: number;
}

const SyncErrorDialog = ({ ...props }: SyncErrorDialogProps): JSX.Element => {
  return (
    <div className="dialog-container">
      <DialogTrigger type="popover" hideArrow>
        <ActionButton isQuiet>
          <Alert aria-label="User Group Sync Alert" size="S" color="notice" />
        </ActionButton>
        {(close) => (
          <Dialog size="S">
            <Heading>Sync status for past 24 hours</Heading>
            <Content>
              <View marginTop="size-200">
                <Text>{props.errorCount} sync issues found</Text>
                <Link to="/insights" style={{ display: 'block' }}>
                  View audit logs for more details
                </Link>
              </View>
            </Content>
            <ButtonGroup>
              <Button variant="primary" onPress={close}>
                Close
              </Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger>
    </div>
  );
};

export default SyncErrorDialog;
