import * as _ from 'lodash';
import config from '../configurations/config';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';

import { UOrgData } from '../services/orgMaster/UOrg';
import { UProductData } from '../services/orgMaster/UProduct';
import { UCompartmentPoliciesData } from '../services/orgMaster/UCompartmentPolicy';
import { UDomainData } from '../services/orgMaster/UDomain';
import Utils from '../services/utils/Utils';
import CountData from '../services/utils/CountData';
import { TEMP_ID_PREFIX } from '../services/orgMaster/OrgMaster';
import { UProductProfileData } from '../services/orgMaster/UProductProfile';
import { UUserGroupData, UUserGroupLicenseGroup, UUserGroup } from '../services/orgMaster/UUserGroup';
import { UAdminData } from '../services/orgMaster/UAdmin';
import { BriefOrganizationTemplateData } from '../models/BriefOrganizationTemplate';
import { OrganizationTemplateData } from '../models/OrganizationTemplate';
import { UResourceData } from '../services/orgMaster/UResource';
import Constants from '../constants/Constants';
import { MESSAGES } from '../Compartments/Messages';
import { OrgAdminType } from '../services/authentication/IMS';
import { ReportInfoData } from '../services/orgMaster/ReportInfo';
import ProviderUtil, { PageOptions, ResponseWithCounts } from '../services/providerUtils/ProviderUtil';

const url = `${config.banyansvc.url}`;

export interface ProfilesMap {
  [productId: string]: UProductProfileData[];
}

export interface UserGroupProfilesMap {
  [userGroupId: string]: UUserGroupLicenseGroup[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface checkT2EESMResponse {
  T2EESMEnabled: boolean;
  isT2EOrg?: boolean;
}

export interface OrgReadOnlyMap {
  [orgId: string]: boolean; // the boolean denotes whether readOnly field is true or false for a given org id
}

export interface OrgToAdminAccessMap {
  [orgId: string]: boolean; // boolean denotes whether user has any of given role(s) on the org.
}

export interface ReadOnlyBulkResponse {
  [readOnlyOrgMap: string]: OrgReadOnlyMap;
}

// contains products grouped by org ids
export interface OrgIdUProductsMap {
  [orgId: string]: UProductData[];
}

// contains products with shared data mapped to offer ids
export interface ProductSharedDataMap {
  [offerId: string]: UProductData;
}

// contains products grouped by org ids but could also include data such as disassembled information
export interface ProductMapResponse {
  productMap: OrgIdUProductsMap;
  sharedProductData: ProductSharedDataMap;
}

// Contains map of orgIds-> UCompartmentPolicies
export interface PoliciesBulkResponse {
  orgPoliciesMap: { [orgId: string]: UCompartmentPoliciesData };
}

export interface ConflictDetail {
  organizationId: string;
  organizationName: string;
  userGroupId: string;
  userGroupName: string;
  mergeAllowed: boolean;
  errorCode?: string;
}

export interface UserGroupConflictsResponse {
  totalConflictGroups: number;
  conflictDetails: ConflictDetail[];
}

/**
 * Provider for communicating with the banyan coompartment restful api.
 * ASR returns errors with a response body in the form:
 *   body:{message:"Application error", reason:"HTTP 404 Not Found"}
 *   body:{message:"System error", reason:"HTTP 500 There was an error processing your request"}
 */
class BanyanCompartmentAPI {
  /**
   * Retrieves org details for the 'orgId'
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing org details of the given org id
   */
  static async getOrgDetails(orgId: string, signal?: AbortSignal): Promise<UOrgData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const getOrgDetailsUrl: string = `${url}/${orgId}`;
    const response = await fetch(getOrgDetailsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgDetailsError);
    return respBody;
  }

  /**
   * Retrieves org details for all the 'orgIds'
   * @param orgIds org ids for bulk call
   * @param signal AbortSignal
   * @returns Promise containing org details of the 'orgIds'
   */
  static async getOrgDetailsBulk(orgIds: string[], signal?: AbortSignal): Promise<UOrgData[]> {
    const getOrgDetailsBulkUrl: string = `${url}/org-details-bulk`;
    const response = await fetch(getOrgDetailsBulkUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(orgIds),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgDetailsError);
    return respBody;
  }

  /**
   * Retrieves hashmap containing the org id and the corresponding isReadOnly boolean
   * @param parentOrgId parent of orgIds where user is explicitly a GlobalAdmin
   * @param orgIds org ids for bulk call
   * @param signal AbortSignal
   * @returns Promise containing org details of the 'orgIds'
   */
  static async getOrgReadOnlyBulk(
    parentOrgId: string,
    orgIds: string[],
    signal?: AbortSignal
  ): Promise<OrgReadOnlyMap> {
    const getReadOnlyOrgsBulkUrl: string = `${url}/read-only-org-info:batchGet`;
    const response = await fetch(getReadOnlyOrgsBulkUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify({
        parentOrgId,
        orgIds,
      }),
      signal,
    });
    const respBody: ReadOnlyBulkResponse = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgReadOnly);
    return respBody.readOnlyOrgMap; // return just the Map, not the full response
  }

  /**
   * Retrieves list the org and all its adescendant orgs, sorted in increasing order, meaning parent orgs are listed before the child orgs. (This is required by all the usages of UOrgMaster.addChildOrgRef which assume that parent org iis already defined)
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing list of orgs which are descendants of the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getHierarchy(orgId: string, signal?: AbortSignal): Promise<UOrgData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const getHierarchyUrl: string = `${url}/${orgId}/hierarchy`;
    const response = await fetch(getHierarchyUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetHierarchyApiError);
    return respBody;
  }

  /**
   * Retrieves the list of products for the given org id.
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing list of products for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getProducts(orgId: string, signal?: AbortSignal): Promise<UProductData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const productsUrl: string = `${url}/${orgId}/products`;
    const response = await fetch(productsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetProductsApiError);
    return respBody;
  }

  /**
   * Retrieves the list of products for multiple org ids in a hierarchy
   * @param parentOrgId parent org id of the parent for the entire hierarchy for all the orgs in orgIds
   * @param orgIds  list of org ids
   * @param signal AbortSignal
   * @returns Promise continaing the list of products grouped to the requested org ids
   */
  static async getHierarchyProductsBulk(
    parentOrgId: string,
    orgIds: string[],
    signal?: AbortSignal
  ): Promise<ProductMapResponse> {
    if (_.isEmpty(orgIds) || !_.isEmpty(_.find(orgIds, (orgId: string): boolean => _.isEmpty(orgId)))) {
      Utils.throwLocalizedError(MESSAGES.OrgIdListEmptyError);
    }

    const hierarchyProductsBulkUrl: string = `${url}/products:batchGet`;
    const response = await fetch(hierarchyProductsBulkUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify({
        parentOrgId,
        orgIds,
      }),
      signal,
    });
    const respBody = (await response.json()) as ProductMapResponse;
    // not sure if there should be a different error message between this method and getProductsBulk especially since this method might replace getProductsBulk
    Utils.throwIfError(response, respBody, MESSAGES.GetProductsBulkApiError);
    return respBody;
  }

  /**
   * Retrieves the list of profiles for the given org id and the product
   * @param orgId id of the org
   * @param productId id of the product
   * @param options PageOptions like page number, page size, search query and sorting order
   * @param signal AbortSignal
   * @returns Promise containing list of profiles and profile count for the given org id and the given product id
   * @throws Error 'orgId cannot be empty' | 'productId cannot be empty'
   */
  static async getProfiles(
    orgId: string,
    productId: string,
    options?: PageOptions,
    signal?: AbortSignal
  ): Promise<ResponseWithCounts> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(productId)) {
      Utils.throwLocalizedError(MESSAGES.ProductIdEmptyError);
    }
    let profilesUrl: string = `${url}/${orgId}/products/${productId}/profiles`;
    if (options) {
      profilesUrl = ProviderUtil.addPageOptions(profilesUrl, options);
    }
    const response = await fetch(profilesUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    return await ProviderUtil.getResponseWithCounts(response, MESSAGES.GetProfilesApiError);
  }

  /**
   * Retrieve profiles for multiple products.
   * Note: This method can be removed when the admins table moves loading of profiles into the dialogs.
   * @param orgId id of the org
   * @param productIds product ids to retrieve profiles for
   * @param signal AbortSignal
   * @returns Map of product ids to profiles
   */
  static async getProfilesBulk(orgId: string, productIds: string[], signal?: AbortSignal): Promise<ProfilesMap> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(productIds) || !_.isEmpty(_.find(productIds, (productId: string): boolean => _.isEmpty(productId)))) {
      Utils.throwLocalizedError(MESSAGES.ProductIdListEmptyError);
    }
    const response = await fetch(`${url}/${orgId}/profiles`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(productIds),
      signal,
    });
    return await response.json();
  }

  /**
   * Retrieves the list of user groups for the given org id
   * @param orgId id of the org
   * @param options PageOptions like page number, page size, search query and sorting order
   * @param signal AbortSignal
   * @returns Promise containing list of user groups for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getUserGroups(orgId: string, options?: PageOptions, signal?: AbortSignal): Promise<ResponseWithCounts> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    let userGroupsUrl: string = `${url}/${orgId}/user-groups`;
    if (options) {
      userGroupsUrl = ProviderUtil.addPageOptions(userGroupsUrl, options);
    }
    const response = await fetch(userGroupsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    return await ProviderUtil.getResponseWithCounts(response, MESSAGES.GetUserGroupsApiError);
  }

  /**
   * Retrieves the list of conflicting user groups for the given grouups
   * @param orgId id of the source org
   * @param targetOrgIds list of target orgIds
   * @param sourceGroupIds list of groupsIds
   * @returns Promise containing list of conflicting user groups for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getUserGroupsConflicts(
    orgId: string,
    targetOrgIds: string[],
    sourceGroups?: UUserGroup[]
  ): Promise<UserGroupConflictsResponse | undefined> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (
      _.isEmpty(targetOrgIds) ||
      !_.isEmpty(_.find(targetOrgIds, (targetOrgId: string): boolean => _.isEmpty(targetOrgId)))
    ) {
      Utils.throwLocalizedError(MESSAGES.OrgIdListEmptyError);
    }
    if (
      _.isEmpty(sourceGroups) ||
      !_.isEmpty(_.find(sourceGroups, (sourceGroup: string): boolean => _.isEmpty(sourceGroup)))
    ) {
      Utils.throwLocalizedError(MESSAGES.UserGroupIdListEmptyError);
    }
    let userGroupsUrl: string = `${url}/${orgId}/check-conflicts-user-groups`;

    const sourceGroupIds: string[] = (sourceGroups || []).map((item) => item.id);
    const response = await fetch(userGroupsUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify({
        targetOrgIds,
        sourceGroupIds,
      }),
    });
    const result: UserGroupConflictsResponse = await response.json();
    Utils.throwIfError(response, result, MESSAGES.GetUserGroupsConflictsApiError);
    return result;
  }

  /**
   * Retrieves the CSV link for conflicting user groups for the given groups
   * @param orgId id of the source org
   * @param targetOrgIds list of target orgIds
   * @param sourceGroups list of groupsIds
   * @returns Promise containing the CSV link for conflicting user groups
   * @throws Error 'orgId cannot be empty'
   */
  static async getUserGroupsConflictsCSV(
    orgId: string,
    targetOrgIds: string[],
    sourceGroups?: UUserGroup[]
  ): Promise<string> {
    if (_.isEmpty(orgId)) {
      throw new Error('orgId cannot be empty');
    }
    if (
      _.isEmpty(targetOrgIds) ||
      !_.isEmpty(_.find(targetOrgIds, (targetOrgId: string): boolean => _.isEmpty(targetOrgId)))
    ) {
      throw new Error('targetOrgIds cannot be empty');
    }
    if (
      _.isEmpty(sourceGroups) ||
      !_.isEmpty(_.find(sourceGroups, (sourceGroup: UUserGroup): boolean => _.isEmpty(sourceGroup.id)))
    ) {
      throw new Error('sourceGroups cannot be empty');
    }

    const userGroupsUrl: string = `${url}/${orgId}/check-conflicts-user-groups/csv`;

    const sourceGroupIds: string[] = (sourceGroups || []).map((item) => item.id);
    const response = await fetch(userGroupsUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify({
        targetOrgIds,
        sourceGroupIds,
      }),
    });

    if (response.ok) {
      const result = await response.text();
      return result;
    } else {
      const error = await response.json();
      Utils.throwIfError(response, error, MESSAGES.GetUserGroupsConflictsCSVApiError);
      return '';
    }
  }

  /**
   * Retrieves list of profiles for user group
   * @param orgId id of the org
   * @param userGroupId  id of the user group
   * @param signal AbortSignal
   * @returns Promise containing list of profiles for requested user group
   */
  static async getProfilesForUserGroup(
    orgId: string,
    userGroupId: string,
    signal?: AbortSignal
  ): Promise<UUserGroupLicenseGroup[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(userGroupId)) {
      Utils.throwLocalizedError(MESSAGES.UserGroupIdEmptyError);
    }
    const response = await fetch(`${url}/${orgId}/user-groups/${userGroupId}/profiles`, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    return await response.json();
  }

  /**
   * Retrieves map of user group ids to a list of product profiles associated with the user group.
   * Note: This method can be removed if loading of profiles for multiple user groups at once is not needed.
   *       (Probably if the use groups table no longer needs to display profiles on list items)
   * @param orgId  id of the org
   * @param userGroupIds  list of user group ids (limit: 1000)
   * @param signal  AbortSignal
   * @returns Promise containing map of user group ids to list of product profiles
   */
  static async getProfilesForUserGroups(
    orgId: string,
    userGroupIds: string[],
    signal?: AbortSignal
  ): Promise<UserGroupProfilesMap> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (
      _.isEmpty(userGroupIds) ||
      !_.isEmpty(_.find(userGroupIds, (userGroupId: string): boolean => _.isEmpty(userGroupId)))
    ) {
      Utils.throwLocalizedError(MESSAGES.UserGroupIdListEmptyError);
    }
    const response = await fetch(`${url}/${orgId}/user-groups/profiles`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(userGroupIds),
      signal,
    });
    return await response.json();
  }

  static async getUserGroupsForProfile(
    orgId: string,
    productId: string,
    profileId: string,
    signal?: AbortSignal
  ): Promise<UUserGroupData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(productId)) {
      Utils.throwLocalizedError(MESSAGES.ProductIdEmptyError);
    }
    if (_.isEmpty(profileId)) {
      Utils.throwLocalizedError(MESSAGES.ProfileIdEmptyError);
    }
    const userGroupsUrl: string = `${url}/${orgId}/products/${productId}/profiles/${profileId}/user-groups`;
    const response = await fetch(userGroupsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetUserGroupsForProfileApiError, { profileId, productId, orgId });
    return respBody;
  }

  /**
   * Retrieves the list of admins for the given org id
   * @param orgId id of the org
   * @param options PageOptions like page number, page size, search query and sorting order
   * @param filterTechAccts determines whether tech accounts will be filtered from results
   * @param signal AbortSignal
   * @returns Promise containing list of admins for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getAdmins(
    orgId: string,
    options?: PageOptions,
    filterTechAccts?: boolean,
    signal?: AbortSignal
  ): Promise<ResponseWithCounts> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    let adminsUrl: string = `${url}/${orgId}/admins`;
    if (options) {
      adminsUrl = ProviderUtil.addPageOptions(adminsUrl, options);
    }
    if (!_.isNil(filterTechAccts)) {
      adminsUrl = ProviderUtil.getURLWithParam(adminsUrl, 'filter_tech_accts', filterTechAccts.toString());
    }
    const response = await fetch(adminsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    return await ProviderUtil.getResponseWithCounts(response, MESSAGES.GetAdminsApiError);
  }

  /**
   * Retrieves the list of admins for the given org id (in a format where there is 1 admin object per user with all roles and targets)
   * TODO: once UUsers are removed, remove "getAdmins" method and rename this method to "getAdmins"
   * @param orgId id of the org
   * @param options PageOptions like page number, page size, search query and sorting order
   * @param filterTechAccts determines whether tech accounts will be filtered from results
   * @param signal AbortSignal
   * @returns Promise containing list of admins for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getAdminsV2(
    orgId: string,
    options?: PageOptions,
    filterTechAccts?: boolean,
    signal?: AbortSignal
  ): Promise<ResponseWithCounts> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    // TODO: once UUsers are removed, remove the "v2" from this url
    let adminsUrl: string = `${url}/${orgId}/admins:v2`;
    if (options) {
      adminsUrl = ProviderUtil.addPageOptions(adminsUrl, options);
    }
    if (!_.isNil(filterTechAccts)) {
      adminsUrl = ProviderUtil.getURLWithParam(adminsUrl, 'filter_tech_accts', filterTechAccts.toString());
    }
    const response = await fetch(adminsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    return await ProviderUtil.getResponseWithCounts(response, MESSAGES.GetAdminsApiError);
  }

  /**
   * Get admins for the given profile (in a format where there is 1 admin object per user with all roles and targets)
   * TODO: rename this method to "getAdminsForProfile"
   * @param orgId id of the org
   * @param productId id of the product
   * @param profileId id of the profile
   * @param signal AbortSignal
   * @returns Promise containing list of admins for the given profile id and org id
   */
  static async getAdminsForProfileV2(
    orgId: string,
    productId: string,
    profileId: string,
    signal?: AbortSignal
  ): Promise<UAdminData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(productId)) {
      Utils.throwLocalizedError(MESSAGES.ProductIdEmptyError);
    }
    if (_.isEmpty(profileId)) {
      Utils.throwLocalizedError(MESSAGES.ProfileIdEmptyError);
    }
    // TODO: once UUsers are removed, remove the "v2" from this url
    const profileAdminsUrl: string = `${url}/${orgId}/products/${productId}/profiles/${profileId}/admins:v2`;
    const response = await fetch(profileAdminsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetAdminsForProfileApiError, { profileId, productId, orgId });
    return respBody;
  }

  /**
   * Get admins for the given user group (in a format where there is 1 admin object per user with all roles and targets)
   * TODO: rename this method to getAdminsForUserGroup
   *
   * @param orgId id of the org
   * @param userGroupId id of the user group
   * @param signal AbortSignal
   * @returns Promise containing list of admins for the given user group id and org id
   */
  static async getAdminsForUserGroupV2(
    orgId: string,
    userGroupId: string,
    signal?: AbortSignal
  ): Promise<UAdminData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(userGroupId)) {
      Utils.throwLocalizedError(MESSAGES.UserGroupIdEmptyError);
    }
    // TODO: once UUsers are removed, remove the "v2" from this url
    const userGroupAdminUrl: string = `${url}/${orgId}/user-groups/${userGroupId}/admins:v2`;
    const response = await fetch(userGroupAdminUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetAdminsForUserGroupApiError, { userGroupId });
    return respBody;
  }

  /**
   * Retrieves the compartment policies for the given org id
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing compartment policies for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getPolicies(orgId: string, signal?: AbortSignal): Promise<UCompartmentPoliciesData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const policiesUrl: string = `${url}/${orgId}/policies`;
    const response = await fetch(policiesUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetPoliciesApiError);
    return respBody;
  }

  /**
   * Retrieves the compartment policies for multiple org ids
   * @param parentOrgId parent of orgIds where user is explicitly a GlobalAdmin.
   * @param orgIds  list of org ids
   * @param signal AbortSignal
   * @returns Promise containing compartment policies for all the requested org ids
   */
  static async getHierarchyPoliciesBulk(
    parentOrgId: string,
    orgIds: string[],
    signal?: AbortSignal
  ): Promise<PoliciesBulkResponse> {
    if (_.isEmpty(orgIds) || !_.isEmpty(_.find(orgIds, (orgId: string): boolean => _.isEmpty(orgId)))) {
      Utils.throwLocalizedError(MESSAGES.OrgIdListEmptyError);
    }
    const policiesBulkUrl: string = `${url}/policies:batchGet`;
    const response = await fetch(policiesBulkUrl, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify({
        parentOrgId,
        orgIds,
      }),
      signal,
    });
    const respBody: PoliciesBulkResponse = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetPoliciesBulkApiError);
    return respBody;
  }

  /**
   * Retrieves the domains for the given org id
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing domains for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getDomains(orgId: string, signal?: AbortSignal): Promise<UDomainData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const domainsUrl: string = `${url}/${orgId}/domains`;
    const response = await fetch(domainsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetDomainsApiError);
    return respBody;
  }

  /**
   * Retrieves the counts of domains, users, user groups and admins for the given org id
   * Note: If includeUsers, includeAdmins, includeUserGroups, and includeDomains are all false, then all data is included
   * as there really is no reason to call this api to get no data.
   *
   * @param orgId id of the org
   * @param signal AbortSignal
   * @param includeUsers determines whether to include the number of users in count
   * @param includeAdmins determines whether to include the number of admins in count
   * @param includeUserGroups determines whether to include the number of user groups in count
   * @param includeDomains determines whether to include the number of of domains in count
   * @returns Promise containing domains for the given org id
   * @throws Error 'orgId cannot be empty' or if AbortError
   */
  static async getCounts(
    orgId: string,
    includeUsers?: boolean,
    includeAdmins?: boolean,
    includeUserGroups?: boolean,
    includeDomains?: boolean,
    signal?: AbortSignal
  ): Promise<CountData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.includes(orgId, TEMP_ID_PREFIX)) {
      return { userCount: '0', adminCount: '0', domainCount: '0', userGroupCount: '0' };
    }

    let countsUrl: string = `${url}/${orgId}/counts`;
    if (includeUsers) {
      countsUrl = ProviderUtil.getURLWithParam(countsUrl, 'include_users', includeUsers);
    }
    if (includeAdmins) {
      countsUrl = ProviderUtil.getURLWithParam(countsUrl, 'include_admins', includeAdmins);
    }
    if (includeUserGroups) {
      countsUrl = ProviderUtil.getURLWithParam(countsUrl, 'include_user_groups', includeUserGroups);
    }
    if (includeDomains) {
      countsUrl = ProviderUtil.getURLWithParam(countsUrl, 'include_domains', includeDomains);
    }
    const response = await fetch(countsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetCountsApiError);
    return respBody;
  }

  /**
   * Retrieves the organization templates for the given org id
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing organization templates for the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getPolicyTemplates(orgId: string, signal?: AbortSignal): Promise<BriefOrganizationTemplateData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const policyTemplateUrl: string = `${url}/${orgId}/policy-templates`;
    const response = await fetch(policyTemplateUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    if (response.status === Constants.NOT_FOUND_HTTP_STATUS) {
      throw Error(response.status.toString());
    }
    Utils.throwIfError(response, respBody, MESSAGES.GetPolicyTemplatesApiError);
    return respBody;
  }

  /**
   * Retrieves the organization template corresponding to templateId for the given org id
   * @param orgId id of the org
   * @param templateId id of the template to be retrieved
   * @param signal AbortSignal
   * @returns Promise containing organization templates for the given org id
   * @throws Error 'orgId cannot be empty'
   * @throws Error 'templateId cannot be empty'
   */
  static async getPolicyTemplate(
    orgId: string,
    templateId: string,
    signal?: AbortSignal
  ): Promise<OrganizationTemplateData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(templateId)) {
      Utils.throwLocalizedError(MESSAGES.TemplateIdEmptyError);
    }
    const policyTemplateUrl: string = `${url}/${orgId}/policy-templates/${templateId}`;
    const response = await fetch(policyTemplateUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetPolicyTemplateApiError);
    return respBody;
  }

  /**
   * Create policy template for an organization corresponding to given org id
   * @param orgId id of the org
   * @param templateBody body of the template to be created
   * @param signal AbortSignal
   * @returns Promise containing newly created organization template for the given org
   * @throws Error 'orgId cannot be empty'
   * @throws Error 'templateBody cannot be empty'
   * @throws Error 'template body missing policies'
   */
  static async createPolicyTemplate(
    orgId: string,
    templateBody: OrganizationTemplateData,
    signal?: AbortSignal
  ): Promise<OrganizationTemplateData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(templateBody)) {
      Utils.throwLocalizedError(MESSAGES.TemplateBodyEmptyError);
    }
    if (_.isEmpty(templateBody.policies)) {
      Utils.throwLocalizedError(MESSAGES.TemplateBodyMissingPoliciesError);
    }
    const policyTemplateUrl: string = `${url}/${orgId}/policy-templates`;
    const response = await fetch(policyTemplateUrl, {
      method: 'POST',
      body: JSON.stringify(templateBody),
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.CreatePolicyTemplateApiError);
    return respBody;
  }

  /**
   * Update policy template corresponsding to templateId for an organization corresponding to given org id
   * @param orgId id of the org
   * @param templateId id of the template to be updated
   * @param templateBody body of the template to be updated
   * @param signal AbortSignal
   * @returns Promise containing updated organization template for the given org
   * @throws Error 'orgId cannot be empty'
   * @throws Error 'templateId cannot be empty'
   * @throws Error 'templateBody cannot be empty'
   * @throws Error 'template body missing policies'
   */
  static async updatePolicyTemplate(
    orgId: string,
    templateId: string,
    templateBody: OrganizationTemplateData,
    signal?: AbortSignal
  ): Promise<OrganizationTemplateData> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(templateId)) {
      Utils.throwLocalizedError(MESSAGES.TemplateIdEmptyError);
    }
    if (_.isEmpty(templateBody)) {
      Utils.throwLocalizedError(MESSAGES.TemplateBodyEmptyError);
    }
    if (_.isEmpty(templateBody.policies)) {
      Utils.throwLocalizedError(MESSAGES.TemplateBodyMissingPoliciesError);
    }
    const policyTemplateUrl: string = `${url}/${orgId}/policy-templates/${templateId}`;
    const response = await fetch(policyTemplateUrl, {
      method: 'PATCH',
      body: JSON.stringify(templateBody),
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.UpdatePolicyTemplateApiError);
    return respBody;
  }

  /**
   * Deletes an organization template corresponding to templateId for the given org id
   * @param orgId id of the org
   * @param templateId id of the template to be deleted
   * @param signal AbortSignal
   * @returns Promise with no content
   * @throws Error 'orgId cannot be empty'
   * @throws Error 'templateId cannot be empty'
   */
  static async deletePolicyTemplate(orgId: string, templateId: string, signal?: AbortSignal): Promise<void> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(templateId)) {
      Utils.throwLocalizedError(MESSAGES.TemplateIdEmptyError);
    }
    const policyTemplateUrl: string = `${url}/${orgId}/policy-templates/${templateId}`;
    const response = await fetch(policyTemplateUrl, {
      method: 'DELETE',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    Utils.throwIfError(response, null, MESSAGES.DeletePolicyTemplateApiError);
  }

  /**
   * Retrieves profile resource options for a product
   * @param orgId id of the org
   * @param productId id of the template to be deleted
   * @param signal AbortSignal
   * @returns Promise with resource options for the profile of that product
   * @throws Error 'orgId cannot be empty'
   * @throws Error 'productId cannot be empty'
   */
  static async getProfileResourceOptions(
    orgId: string,
    productId: string,
    signal?: AbortSignal
  ): Promise<UResourceData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(productId)) {
      Utils.throwLocalizedError(MESSAGES.ProductIdEmptyError);
    }
    const profileResourceOptionsURL: string = `${url}/${orgId}/products/${productId}/default-profile-resources`;
    const response = await fetch(profileResourceOptionsURL, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetProfileResourceOptionsApiError, { productId });
    return respBody;
  }

  /**
   * Checks whether orgs are t2e and esm
   * @param orgId org id to check
   * @param t2eInfo set this value to true if isT2EOrg is desired in the response
   * @param signal AbortSignal
   * @returns Promise containing map of "T2EESMEnabled" to boolean
   */
  static async checkT2EESM(orgId: string, t2eInfo?: boolean, signal?: AbortSignal): Promise<checkT2EESMResponse> {
    const queryParams: URLSearchParams = new URLSearchParams();
    if (t2eInfo) {
      queryParams.append('t2e_info', `${t2eInfo}`);
    }

    const response: Response = await fetch(`${url}/${orgId}/t2eesm?${queryParams.toString()}`, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetT2EESMDataError);
    return respBody;
  }

  /**
   * Checks whether current user has EXPLICIT system admin access to given org.
   * Note: If API call fails with error, return false.
   *
   * @param orgId org id to check
   * @returns true if user has EXPLICIT system admin access; false otherwise
   */
  static async isCurrentUserSystemAdminOf(orgId: string): Promise<boolean> {
    const adminRoles = [OrgAdminType.ORG_ADMIN];
    const accessMap: OrgToAdminAccessMap = await BanyanCompartmentAPI.getOrgToAdminAccessMap(
      adminRoles,
      [orgId],
      true,
      true
    );
    return _.isUndefined(accessMap[orgId]) ? false : accessMap[orgId];
  }

  /**
   * Checks whether current user has ANY OF the agentRoles on the authenticating account.
   * Note: If API call fails with error, return false.
   *
   * @param agentRoles list of agent roles for which check is to be made
   * @returns true if user has any of agentRoles; false otherwise
   */
  static async checkAgentAccess(agentRoles: string[]): Promise<boolean> {
    const queryParams: URLSearchParams = new URLSearchParams();

    if (agentRoles.length > 0) {
      queryParams.append('agent_roles', agentRoles.join(','));
    }

    const response: Response = await fetch(`${url}/agent-access?${queryParams.toString()}`, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const data: { hasAccess: boolean } = await response.json();
    if (response.status !== 200) {
      return false;
    }
    return data.hasAccess;
  }

  /**
   * Checks whether current user has EXPLICIT presence as ONE OF the adminRoles for given org or across orgs of linked user accounts.
   * Note: If API call fails with error, return empty map.
   *
   * @param adminRoles list of admin roles for which check is to be made
   * @param orgIds org ids to check adminRoles against
   * @param explicitAccessOnly if true, check for EXPLICIT presence of adminRoles on orgIds; otherwise adminRoles could be EXPLICIT or IMPLICIT
   * @param acrossCluster if true, check for adminRoles across linked user accounts; otherwise check current user profile ONLY
   * @returns map of orgId to boolean indicating if user has any of adminRoles in corresponding orgId
   */
  static async getOrgToAdminAccessMap(
    adminRoles: string[],
    orgIds?: string[],
    explicitAccessOnly?: boolean,
    acrossCluster?: boolean
  ): Promise<OrgToAdminAccessMap> {
    const queryParams: URLSearchParams = new URLSearchParams();

    if (adminRoles.length > 0) {
      queryParams.append('admin_roles', adminRoles.join(','));
    }

    if (explicitAccessOnly != undefined) {
      queryParams.append('explicit_access_only', `${explicitAccessOnly}`);
    }

    if (acrossCluster != undefined) {
      queryParams.append('across_cluster', `${acrossCluster}`);
    }

    const response: Response = await fetch(`${url}/org-access-map:batchGet?${queryParams.toString()}`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(orgIds),
    });
    const data: OrgToAdminAccessMap = await response.json();
    if (response.status !== 200) {
      return {};
    }
    return data;
  }

  /**
   * Retrieves ESM | Export reports for an organization
   * @param orgId id of the org
   * @param pageSize no of reports to be fetched at once
   * @param lastReportBaseKey base key of the last report
   * @returns Promise containing reports of an org
   * @throws Error 'orgId cannot be empty'
   */
  static async getReportsForOrg(
    orgId: string,
    lastReportBaseKey?: string,
    pageSize?: number
  ): Promise<ReportInfoData[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }

    let reportsURL: string = `${url}/${orgId}/reports`;
    if (pageSize !== undefined) {
      reportsURL = ProviderUtil.getURLWithParam(reportsURL, 'page_size', pageSize);
    }
    if (lastReportBaseKey !== undefined) {
      reportsURL = ProviderUtil.getURLWithParam(reportsURL, 'last_report_base_key', lastReportBaseKey);
    }
    const response = await fetch(reportsURL, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const respBody = await response.json();
    // get the default error message based on the type of report being fetched
    const defaultErrorMessage = MESSAGES.GetOrganizationReportError;
    Utils.throwIfError(response, respBody, defaultErrorMessage);
    return respBody;
  }

  static async getPresignedFileURL(orgId: string, fileKey: string, fileName: string): Promise<string> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    if (_.isEmpty(fileKey)) {
      Utils.throwLocalizedError(MESSAGES.FileKeyEmptyError);
    }
    if (_.isEmpty(fileName)) {
      Utils.throwLocalizedError(MESSAGES.FileNameEmptyError);
    }
    const getUrl: string = `${url}/${orgId}/generate-file-url?fileName=${encodeURI(fileName)}&fileKey=${encodeURI(
      fileKey
    )}`;
    const response = await fetch(getUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    return await response.text();
  }
}

export default BanyanCompartmentAPI;
