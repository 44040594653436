import React, { ReactElement } from 'react';
import { Flex, Text, View } from '@adobe/react-spectrum';
import './OverwriteUserGroupsView.css';

interface IconWithMessageProps {
  message: string;
  icon: ReactElement;
  size?: number;
  color?: string;
}

const IconWithMessage: React.FC<IconWithMessageProps> = ({ message, icon }) => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
      <div>
        <Text alignSelf="center">{message}</Text>
        <View>{icon}</View>
      </div>
    </Flex>
  );
};

export default IconWithMessage;
