import { defineMessages, MessageDescriptor } from 'react-intl';

/**
 * This would be central place for defining messages for users, generated from `Organization` tab, which would need react-intl feature.
 * Defining messages here would help avoid react-intl related code duplication.
 * e.g. Error messages, Common headers/title, Dialogs etc.
 */
export const MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  ErrorDialogTitle: {
    id: 'EditCompartment.ErrorDialogTitle',
    defaultMessage: 'Error',
  },
  InformationDialogTitle: {
    id: 'EditCompartment.InformationDialogTitle',
    defaultMessage: 'Information',
  },
  ConfirmationDialogTitle: {
    id: 'EditCompartment.ConfirmationDialogTitle',
    defaultMessage: 'Do you confirm?',
  },
  DestructiveDialogTitle: {
    id: 'EditCompartment.DestructiveDialogTitle',
    defaultMessage: 'Are you sure?',
  },
  MessageDialogConfirm: {
    id: 'EditCompartment.MessageDialogConfirm',
    defaultMessage: 'OK',
  },
  MessageDialogProceed: {
    id: 'EditCompartment.MessageDialogProceed',
    defaultMessage: 'Yes',
  },
  MessageDialogCancel: {
    id: 'EditCompartment.MessageDialogCancel',
    defaultMessage: 'Cancel',
  },
  SearchError: {
    id: 'EditCompartment.SearchErrorMessage',
    defaultMessage: 'Enter 3 or more characters for full search',
  },
  TemplateGETApiError: {
    id: 'EditCompartment.TemplateApiError.GET',
    defaultMessage: 'Failed to retrieve template(s)',
  },
  TemplatePOSTApiError: {
    id: 'EditCompartment.TemplateApiError.POST',
    defaultMessage: 'Failed to create template',
  },
  TemplatePATCHApiError: {
    id: 'EditCompartment.TemplateApiError.PATCH',
    defaultMessage: 'Failed to update template',
  },
  TemplateDELETEApiError: {
    id: 'EditCompartment.TemplateApiError.DELETE',
    defaultMessage: 'Failed to delete template {templateName}',
  },
  TemplatePOSTApiSuccess: {
    id: 'EditCompartment.TemplateApiSuccess.POST',
    defaultMessage: 'Template {templateName} created successfully',
  },
  TemplatePATCHApiSuccess: {
    id: 'EditCompartment.TemplateApiSuccess.PATCH',
    defaultMessage: 'Template {templateName} updated successfully',
  },
  TemplateDELETEApiSuccess: {
    id: 'EditCompartment.TemplateApiSuccess.DELETE',
    defaultMessage: 'Template {templateName} deleted successfully',
  },
  TemplateDeleteConfirmation: {
    id: 'EditCompartment.Template.Delete.ConfirmationMessage',
    defaultMessage: 'Delete template {templateName}',
  },
  OrgIdEmptyError: {
    id: 'BanyanCompartmentsApi.OrgIdEmptyError',
    defaultMessage: 'OrgId cannot be empty',
  },
  OrgIdListEmptyError: {
    id: 'BanyanCompartmentsApi.OrgIdListEmptyError',
    defaultMessage: 'List of org ids cannot be empty or contain an empty org id',
  },
  ProductIdEmptyError: {
    id: 'BanyanCompartmentsApi.ProductIdEmptyError',
    defaultMessage: 'ProductId cannot be empty',
  },
  ProductIdListEmptyError: {
    // This error is only used by BanyanCompartmentAPI.getProfilesBulk.
    // This error is not needed if the method is removed most likely due to the admins table moving  loading of profiles to the dialogs.
    id: 'BanyanCompartmentsApi.ProductIdListEmptyError',
    defaultMessage: 'List of product IDs cannot be empty or contain an empty product ID',
  },
  ProfileIdEmptyError: {
    id: 'BanyanCompartmentsApi.ProfileIdEmptyError',
    defaultMessage: 'Profile id cannot be empty',
  },
  UserGroupIdEmptyError: {
    id: 'BanyanCompartmentsApi.UserGroupIdEmptyError',
    defaultMessage: 'User group id cannot be empty',
  },
  UserGroupIdListEmptyError: {
    // This error is only used by BanyanCompartmentAPi.getProfilesForUserGroups.
    // This error is not needed if the method is removed most likely due to no longer needing profiles for multiple user groups at once.
    id: 'BanyanCompartmentsApi.UserGroupIdListEmptyError',
    defaultMessage: 'List of user group IDs cannot be empty or contain an empty user group ID',
  },
  GetCountryApiError: {
    id: 'ImsProvider.GetCountryApiError',
    defaultMessage: 'Unable to load the countries',
  },
  GetUserProfileApiError: {
    id: 'ImsProvider.GetUserProfileApiError',
    defaultMessage: 'Unable to load the user profile',
  },
  GetHierarchyApiError: {
    id: 'BanyanCompartmentsApi.GetHierarchy.Error',
    defaultMessage: 'Unable to load the orgs',
  },
  GetProductsApiError: {
    id: 'BanyanCompartmentsApi.GetProducts.Error',
    defaultMessage: 'Unable to load the products',
  },
  GetProductsBulkApiError: {
    id: 'BanyanCompartmentsApi.GetProductsBulk.Error',
    defaultMessage: 'Unable to load the products for multiple orgs',
  },
  GetProfilesApiError: {
    id: 'BanyanCompartmentsApi.GetProfiles.Error',
    defaultMessage: 'Unable to load the profiles',
  },
  GetUserGroupsApiError: {
    id: 'BanyanCompartmentsApi.GetUserGroups.Error',
    defaultMessage: 'Unable to load the user groups',
  },
  GetUserGroupsConflictsApiError: {
    id: 'BanyanCompartmentsApi.GetUserGroupsConflict.Error',
    defaultMessage: 'Unable to load the user groups conflict',
  },
  GetUserGroupsConflictsCSVApiError: {
    id: 'BanyanCompartmentsApi.GetUserGroupsConflictsCSV.Error',
    defaultMessage: 'Unable to download the user groups conflicts CSV',
  },
  GetUserGroupsForProfileApiError: {
    id: 'BanyanCompartmentsApi.GetUserGroupsForProfile.Error',
    defaultMessage: 'Unable to load the user groups for profile {profileId}, product {productId}, org {orgId}',
  },
  GetAdminsApiError: {
    id: 'BanyanCompartmentsApi.GetAdmins.Error',
    defaultMessage: 'Unable to load the admins',
  },
  GetAdminsForProfileApiError: {
    id: 'BanyanCompartmentsApi.GetAdminsForProfile.Error',
    defaultMessage: 'Unable to load the admins for the profile {profileId}, product {productId}, org {orgId}',
  },
  GetAdminsForUserGroupApiError: {
    id: 'BanyanCompartmentsApi.GetAdminsForUserGroup.Error',
    defaultMessage: 'Unable to load the admins for the user group {userGroupId}',
  },
  GetAllowedAdminsError: {
    id: 'BanyanCompartmentsApi.GetAllowedAdminsError.Error',
    defaultMessage: 'Unable to fetch allowed admin types',
  },
  GetPoliciesApiError: {
    id: 'BanyanCompartmentsApi.GetPolicies.Error',
    defaultMessage: 'Unable to load the policies',
  },
  GetPoliciesBulkApiError: {
    id: 'BanyanCompartmentsApi.GetPoliciesBulk.Error',
    defaultMessage: 'Unable to load the policies for multiple orgs',
  },
  GetDomainsApiError: {
    id: 'BanyanCompartmentsApi.GetDomains.Error',
    defaultMessage: 'Unable to load the domains',
  },
  GetCountsApiError: {
    id: 'BanyanCompartmentsApi.GetCounts.Error',
    defaultMessage: 'Unable to load the counts',
  },
  TemplateIdEmptyError: {
    id: 'BanyanCompartmentsApi.TemplateIdEmptyError',
    defaultMessage: 'Template id cannot be empty',
  },
  TemplateBodyEmptyError: {
    id: 'BanyanCompartmentsApi.TemplateBodyEmptyError',
    defaultMessage: 'Template body cannot be empty',
  },
  TemplateBodyMissingPoliciesError: {
    id: 'BanyanCompartmentsApi.TemplateBodyMissingPoliciesError',
    defaultMessage: 'Template body missing policies',
  },
  GetPolicyTemplatesApiError: {
    id: 'BanyanCompartmentsApi.GetPolicyTemplates.Error',
    defaultMessage: 'Unable to load the organization templates',
  },
  GetPolicyTemplateApiError: {
    id: 'BanyanCompartmentsApi.GetPolicyTemplate.Error',
    defaultMessage: 'Unable to retrieve an organization template',
  },
  CreatePolicyTemplateApiError: {
    id: 'BanyanCompartmentsApi.CreatePolicyTemplate.Error',
    defaultMessage: 'Unable to create an organization template',
  },
  UpdatePolicyTemplateApiError: {
    id: 'BanyanCompartmentsApi.UpdatePolicyTemplate.Error',
    defaultMessage: 'Unable to update an organization template',
  },
  DeletePolicyTemplateApiError: {
    id: 'BanyanCompartmentsApi.DeletePolicyTemplate.Error',
    defaultMessage: 'Unable to delete an organization template',
  },
  GetProfileResourceOptionsApiError: {
    id: 'BanyanCompartmentsApi.GetProfileResourceOptions.Error',
    defaultMessage: 'Unable to fetch profile resource options for product {productId}',
  },
  ExportError: {
    id: 'ExportCompartmentsProvider.ExportError',
    defaultMessage: 'Error while exporting data. Please check the export criteria.',
  },
  ExportTimeoutError: {
    id: 'ExportCompartmentsProvider.ExportTimeoutError',
    defaultMessage: 'Export timed out after 15 minutes. Please try exporting less data.',
  },
  GetOrganizationsApiError: {
    id: 'OrganizationListProvider.GetOrganizations.Error',
    defaultMessage: 'Unable to load the organizations.',
  },
  GetOrgInfoApiError: {
    id: 'OrganizationListProvider.GetOrgInfo.Error',
    defaultMessage: 'Unable to get org info for orgs',
  },
  GetRootOrgIdApiError: {
    id: 'OrganizationListProvider.GetRootOrgId.Error',
    defaultMessage: 'Unable to get root orgId',
  },
  GetAncestorsApiError: {
    id: 'BanyanCompartmentsApi.GetAncestors.Error',
    defaultMessage: 'Unable to load the ancestor orgs',
  },
  GetOrgApiError: {
    id: 'BanyanCompartmentsApi.GetOrgApiError.Error',
    defaultMessage: 'Unable to load org with ID {orgId}',
  },
  NoPermissionToViewOrg: {
    id: 'Organizations.Error.NoPermission',
    defaultMessage: 'You do not have permission to view this org.',
  },
  VerifyOrgError: {
    id: 'Organizations.Error.VerifyOrgError',
    defaultMessage: 'Unable to verify the org.',
  },
  GetT2EESMDataError: {
    id: 'Agent.T2E.Error.Unknown',
    defaultMessage: 'Error reading organization data',
  },
  GetOrgDetailsError: {
    id: 'BanyanCompartmentsApi.GetOrgDetails.Error',
    defaultMessage: 'Unable to load org details.',
  },
  GetContractsError: {
    id: 'BanyanCompartmentsApi.GetContractsError.Error',
    defaultMessage: 'Unable to load org contracts.',
  },
  GetOrgReadOnly: {
    id: 'BanyanCompartmentsApi.GetOrgReadOnly.Error',
    defaultMessage: 'Unable to load org read only data.',
  },
  ReparentUserNotification: {
    id: 'ReparentUserNotification',
    defaultMessage:
      'Reparent edits can not be submitted with other types of changes in the same job. Submit reparent changes first and then make other types of changes.',
  },

  /* Organization policy messages start */
  OrgAndAdminPolicyGroupHeading: {
    id: 'Organizations.Policies.GroupHeading.OrgAndAdmin',
    defaultMessage: 'Organizations and administrators',
  },
  IdentityAndUsersPolicyGroupHeading: {
    id: 'Organizations.Policies.GroupHeading.IdentityAndUsers',
    defaultMessage: 'Identity and users',
  },
  AllocationPolicyGroupHeading: {
    id: 'Organizations.Policies.GroupHeading.Allocation',
    defaultMessage: 'Product Allocation',
  },
  AssetSharingPolicyGroupHeading: {
    id: 'Organizations.Policies.GroupHeading.AssetSharing',
    defaultMessage: 'Asset sharing',
  },
  CreateChildPolicy: {
    id: 'Organizations.Policies.CreateChild',
    defaultMessage: 'Create child organizations',
  },
  ManageAdminsPolicy: {
    id: 'Organizations.Policies.ManageAdmins',
    defaultMessage: 'Add or delete administrators',
  },
  RenameOrgsPolicy: {
    id: 'Organizations.Policies.RenameOrgs',
    defaultMessage: 'Rename organization',
  },
  DeleteOrgsPolicy: {
    id: 'Organizations.Policies.DeleteOrgs',
    defaultMessage: 'Delete organization',
  },
  InheritAdminsPolicy: {
    id: 'Organizations.Policies.InheritAdmins',
    defaultMessage: 'Inherit system admins from parent when child organization is created',
  },
  InheritUsersPolicy: {
    id: 'Organizations.Policies.InheritUsers',
    defaultMessage: 'Inherit users from directories managed by the parent organization',
  },
  DupGroupsPolicy: {
    id: 'Organizations.Policies.DupGroups',
    defaultMessage: 'Duplicate user groups to another organization (later release)',
  },
  ClaimDomainsPolicy: {
    id: 'Organizations.Policies.ClaimDomains',
    defaultMessage: 'Claim domains',
  },
  ManageUserGroupsPolicy: {
    id: 'Organizations.Policies.ManageUserGroups',
    defaultMessage: 'Manage User Groups',
  },
  AdobeIdPolicy: {
    id: 'Organizations.Policies.AdobeId',
    defaultMessage: 'Add Adobe ID users',
  },
  IdConfigPolicy: {
    id: 'Organizations.Policies.IdConfig',
    defaultMessage: 'Change identity configuration',
  },
  MoveDirPolicy: {
    id: 'Organizations.Policies.MoveDir',
    defaultMessage: 'Move a directory (with owned domains) to another organization (later release)',
  },
  NonChildAllocPolicy: {
    id: 'Organizations.Policies.NonChildAlloc',
    defaultMessage: 'Allocate product resources to organizations other than child organizations (later release)',
  },
  ManageProductsPolicy: {
    id: 'Organizations.Policies.ManageProducts',
    defaultMessage: 'Manage products',
  },
  SetSharingPolicy: {
    id: 'Organizations.Policies.SetSharingPolicy',
    defaultMessage: 'System or Storage admin can change asset sharing settings',
  },
  InheritSharingPolicy: {
    id: 'Organizations.Policies.InheritSharingPolicy',
    defaultMessage: 'Inherit sharing policy from parent when organization is created',
  },
  SetWorkspacesPolicy: {
    id: 'Organizations.Policies.SetWorkspacesPolicy',
    defaultMessage: 'System or Storage admin can change workspace policies',
  },
  InheritWorkspacesPolicy: {
    id: 'Organizations.Policies.InheritWorkspacesPolicy',
    defaultMessage: 'Inherit workspace policy from parent when organization is created',
  },
  GetProductsError: {
    id: 'BanyanCompartmentsApi.GetProductsError.Error',
    defaultMessage: 'Unable to load org products',
  },
  /* Organization policy messages end */
});
