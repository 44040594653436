/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import React from 'react';
import * as _ from 'lodash';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import { defineMessages, FormattedMessage, injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UUserGroup } from '../../../services/orgMaster/UUserGroup';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import AdminPermission from '../../../services/authentication/AdminPermission';
import SearchError from '../SearchError/SearchError';
import '../../common.css';
import Analytics from '../../../Analytics/Analytics';
import JILSearch from '../services/JILSearch';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import ScrollableContent from '../Widgets/ScrollableContent';
import { CommandService } from '../../../services/Commands/CommandService';
import Utils from '../../../services/utils/Utils';
import { Button as ButtonV3, DialogTrigger, Flex, Text } from '@adobe/react-spectrum';
import { ContextualHelpContentModel } from './types';
import { ContextualHelp } from './ContextualHelp';
import { UserGroupsTable } from './UserGroupsTable';
import EditUserGroupDialogContent, {
  EditUserGroupDialogContentProps,
  EditUserGroupDialogContext,
} from './EditUserGroupDialogContent/EditUserGroupDialogContent';
import { UUserGroupShare } from '../../../services/orgMaster/UUserGroupShare';
import { UserGroupConflictsResponse } from '../../../providers/BanyanCompartmentAPI';
import { ObjectTypes, OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { CreateUserGroupShareDialog } from '../../../components/CreateUserGroupShareDialog';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';
import { UserGroupSharingErrorProvider } from '../../../providers/UserGroupSharingErrorProvider';
import FloodgateService from '../../../services/floodgate/FloodgateService';

interface UserGroupsTabProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
  isUserGroupSharingEnabled: boolean;
}

interface UserGroupsTabState {
  filteredUserGroups: UUserGroup[];
  searchInput: string;
  errorMessage: string;
  loadingSearchResults: boolean;
  displayLoadingForUserGroupCounts: boolean;
  showSearchError: boolean;
  selectedUserGroups: Array<UUserGroup>;
  isCheckboxesSelected: boolean;
  logsList: any[];
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function UserGroupDialogIntl(props: Omit<EditUserGroupDialogContentProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditUserGroupDialogContent {...props} />
    </IntlProvider>
  );
}

const messages = defineMessages({
  SearchPlaceholder: {
    id: 'EditCompartment.UserGroups.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.UserGroups.Helptext',
    defaultMessage:
      'A user group is a set of users.  Often user groups are used to grant a group of users access to a product which is done by associating the user group with a product profile of a product. ',
  },
  FailedToLoadUserGroup: {
    id: 'Organizations.UserGroups.FailedToLoadUserGroup',
    defaultMessage: 'Unable to load user group data: {error}',
  },
  UserGroupsLableSingular: {
    id: 'EditCompartment.UserGroups.UserGroupsLableSingular',
    defaultMessage: 'user group',
  },
  UserGroupsLablePlural: {
    id: 'EditCompartment.UserGroups.UserGroupsLablePlural',
    defaultMessage: 'user groups',
  },
  Warning: {
    id: 'EditCompartment.UserGroups.Delete.Warning',
    defaultMessage: 'Warning',
  },
  OK: {
    id: 'EditCompartment.UserGroups.Delete.OK',
    defaultMessage: 'Ok',
  },
  Cancel: {
    id: 'EditCompartment.UserGroups.Delete.Cancel',
    defaultMessage: 'Cancel',
  },
  AreYouSure: {
    id: 'EditCompartment.UserGroups.Delete.confirmDelete',
    defaultMessage: 'Are you sure you want to delete {group}?',
  },
  ContextualHelpHeadingLeafOrg: {
    id: 'EditCompartment.UserGroups.ContextualHelp.Heading.LeafOrg',
    defaultMessage: 'User group sharing not available',
  },
  ContextualHelpBodyLeafOrg: {
    id: 'EditCompartment.UserGroups.ContextualHelp.Body.LeafOrg',
    defaultMessage: 'There are no lower child orgs to share to.',
  },
  ContextualHelpHeadingNoUserGroupSelected: {
    id: 'EditCompartment.UserGroups.ContextualHelp.Heading.NoUserGroupSelected',
    defaultMessage: 'Select a user group to share',
  },
  ContextualHelpBodyNoUserGroupSelected: {
    id: 'EditCompartment.UserGroups.ContextualHelp.Body.NoUserGroupSelected',
    defaultMessage: 'At least one user group must be selected.',
  },
});

class UserGroupsTab extends React.Component<UserGroupsTabProps, UserGroupsTabState> {
  searchTimer: NodeJS.Timeout | undefined; // Store the search timer. Previous timer is replaced with a new one while the user is typing. Once the user stops typing, the search is fired after 1second.
  abortController: AbortController;

  constructor(props: UserGroupsTabProps) {
    super(props);
    this.state = {
      filteredUserGroups: this.props.selectedOrg.userGroups,
      searchInput: '',
      errorMessage: '',
      loadingSearchResults: false,
      displayLoadingForUserGroupCounts: true,
      showSearchError: false,
      selectedUserGroups: [],
      isCheckboxesSelected: false,
      logsList: [],
    };
    this.abortController = new AbortController();
  }

  /**
   * Iterates over the selected user groups and orgs
   * and creates a user group share for each user group along
   * with the strategy if required- org pair
   * @param userGroups The selected user groups
   * @param orgIds The list of selected target org ids
   */
  createJob = (
    userGroups: UUserGroup[],
    orgIds: string[],
    overwriteStrategy: string,
    apiResponse: UserGroupConflictsResponse
  ): void => {
    for (const userGroup of userGroups) {
      for (const targetOrgId of orgIds) {
        const hasConflict = apiResponse?.conflictDetails.some(
          (conflict) =>
            conflict.organizationId === targetOrgId &&
            conflict.userGroupId === userGroup.id &&
            (!conflict.mergeAllowed || (conflict.mergeAllowed && overwriteStrategy === 'IGNORE'))
        );
        if (hasConflict) {
          continue;
        }
        const isMergeAllowed = apiResponse?.conflictDetails.some(
          (conflict) =>
            conflict.organizationId === targetOrgId && conflict.userGroupId === userGroup.id && conflict.mergeAllowed
        );
        const allowOverwrite = isMergeAllowed && (overwriteStrategy === 'ADD_ON' || overwriteStrategy === 'MIRROR');
        const userGroupShare = new UUserGroupShare({
          sourceOrgId: this.props.selectedOrg.id,
          targetOrgId: targetOrgId,
          sourceGroupId: userGroup.id,
          userGroupName: userGroup.name,
          ...(allowOverwrite && { allowOverwrite, overwriteStrategy }),
        });
        CommandService.addEdit(
          this.props.selectedOrg,
          userGroupShare,
          ObjectTypes.USER_GROUP_SHARE,
          OrgOperation.CREATE,
          undefined,
          'CREATE_USER_GROUP_SHARE',
          [
            CmdDescriptionUtils.getPathname(userGroupShare.sourceOrgId),
            userGroup.name,
            CmdDescriptionUtils.getPathname(targetOrgId),
          ]
        );
      }
    }

    this.props.update();
  };

  getDisabledReason = (org: UOrgMaster): ContextualHelpContentModel => {
    let disabledReason = {} as ContextualHelpContentModel;
    const { formatMessage } = this.props.intl;

    if (Utils.isLeafOrg(org)) {
      disabledReason.heading = formatMessage(messages.ContextualHelpHeadingLeafOrg);
      disabledReason.body = formatMessage(messages.ContextualHelpBodyLeafOrg);
    } else if (!this.state.isCheckboxesSelected) {
      disabledReason.heading = formatMessage(messages.ContextualHelpHeadingNoUserGroupSelected);
      disabledReason.body = formatMessage(messages.ContextualHelpBodyNoUserGroupSelected);
    }

    return disabledReason;
  };

  /**
   * load user groups
   */
  private async loadUserGroups(): Promise<void> {
    try {
      if (!this.props.selectedOrg.userGroupsLoaded) {
        await LoadOrgDataService.loadProducts(this.props.selectedOrg.id, this.abortController.signal);
        await LoadOrgDataService.loadUserGroups(this.props.selectedOrg.id, false, this.abortController.signal);
      }
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState({
        errorMessage: '', // reset error message
        filteredUserGroups: this.getFilteredUserGroups(this.props.selectedOrg),
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      // if loading fails, set the errorMessage and show alert box
      const { formatMessage } = this.props.intl;
      this.setState({ errorMessage: formatMessage(messages.FailedToLoadUserGroup, { error: error.message }) });
    }
  }

  private async loadUserGroupCounts(): Promise<void> {
    await LoadOrgDataService.loadUserGroupCounts(this.props.selectedOrg.id, this.abortController.signal);
    if (this.abortController.signal.aborted) return;
    this.setState({ displayLoadingForUserGroupCounts: false });
  }

  private async fetchAuditLogs(): Promise<void> {
    const auditLogs = await UserGroupSharingErrorProvider.fetchErrorLogsWithRetry(this.props.selectedOrg.id);
    if (this.abortController.signal.aborted) return;
    this.setState({ logsList: auditLogs });
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadUserGroups();
    await this.loadUserGroupCounts();
    if (FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS)) {
      const fetchLogsPromise = this.fetchAuditLogs();
      await fetchLogsPromise;
    }
  }

  async componentDidUpdate(prevProps: UserGroupsTabProps): Promise<void> {
    const isUserGroupListChange = !_.isEqual(
      this.state.filteredUserGroups,
      this.getFilteredUserGroups(this.props.selectedOrg)
    );
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id || isUserGroupListChange) {
      await this.loadUserGroups(); // load user groups if the changed compartment has not loaded the user groups
    }
  }

  private getFilteredUserGroups(compartment: UOrgMaster): UUserGroup[] {
    if (this.state.searchInput.length < 3) {
      // no filtering with search string less than 3 characters because server side search expects 3 or more characters
      return compartment.userGroups;
    }
    return _.filter(compartment.userGroups, (userGroup: UUserGroup): boolean =>
      _.includes(_.toLower(userGroup.name), _.toLower(this.state.searchInput))
    );
  }

  private onSearch = async (searchInput: string): Promise<void> => {
    const filterQuery = _.trim(searchInput);
    if (_.isEmpty(filterQuery)) {
      this.setState({ filteredUserGroups: this.props.selectedOrg.userGroups });
      return;
    }
    if (filterQuery.length < 3) {
      this.setState({ showSearchError: true });
      return;
    }
    Analytics.fireCTAEvent('user group search');
    this.setState({
      filteredUserGroups: this.getFilteredUserGroups(this.props.selectedOrg),
    });
    if (this.searchTimer) {
      // clear timeout as the user is still typing
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(async (): Promise<void> => {
      // if there are more user group which have not yet been loaded, call user group jil search and add the matching user groups to the compartment to show them
      if (this.props.selectedOrg.isMoreUserGroups() || !this.props.selectedOrg.userGroupsLoaded) {
        // show load symbol while waiting for response of search query
        this.setState({ loadingSearchResults: true });
        await JILSearch.userGroupSearch(this.props.selectedOrg, filterQuery);
        this.setState({
          filteredUserGroups: this.getFilteredUserGroups(this.props.selectedOrg),
          loadingSearchResults: false,
        });
      }
    }, 1000); // The search API call is made 1 second after the time user stops typing
  };

  morePagesToLoad(): boolean {
    return this.props.selectedOrg.currentUserGroupPageIndex + 1 < this.props.selectedOrg.totalUserGroupPageCount;
  }

  /**
   * Load next page of user groups
   */
  loadNextPage = async (): Promise<void> => {
    // get next list of user groups
    await LoadOrgDataService.loadNextPageUserGroup(this.props.selectedOrg.id);
    this.setState({ filteredUserGroups: this.getFilteredUserGroups(this.props.selectedOrg) }); // rerender with updated values
  };

  public render(): React.ReactElement {
    const { formatMessage } = this.props.intl;

    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in user group table if we are unable to load the user group data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }

    const isAddDisabled =
      !this.props.selectedOrg.policiesLoaded ||
      !this.props.selectedOrg.compartmentPolicy.policies.manageUserGroups.value;
    const finalTotalUserGroups =
      this.props.selectedOrg.totalUserGroupCount +
      this.props.selectedOrg.getNewUserGroupCount() -
      CommandService.getNumOfDeletedUserGroup(this.props.selectedOrg.id); // adjust for added and deleted user groups

    const readOnlyMode = AdminPermission.readOnlyMode();
    const hasSharableGroups = finalTotalUserGroups > 0;
    const isLeafOrg = Utils.isLeafOrg(this.props.selectedOrg);
    const userGroupsSelected = this.state.isCheckboxesSelected;

    // show wait till the user groups are loaded
    return this.props.selectedOrg.userGroupsLoaded ? (
      <div>
        <div className="EditCompartment_tabSectionHeader">
          <Flex>
            <Search
              placeholder={formatMessage(messages.SearchPlaceholder)}
              onChange={(searchInput: string): void => {
                if (!searchInput) {
                  // reset the user group if searchInput is empty
                  this.setState({
                    filteredUserGroups: this.props.selectedOrg.userGroups,
                    searchInput,
                    showSearchError: false,
                  });
                } else {
                  this.setState({ searchInput, showSearchError: false });
                }
                this.onSearch(searchInput);
              }}
              value={this.state.searchInput}
              className="EditCompartment__TableSearch"
              data-testid="user-group-table-user-group-search"
            />
            {this.props.isUserGroupSharingEnabled && !readOnlyMode && (
              <Text alignSelf="center" marginStart="size-100">
                <FormattedMessage
                  id="EditCompartment.UserGroupTableSelectedCountDisplay"
                  description="Display count of selected user groups"
                  defaultMessage="{selectedUserGroupCount} selected"
                  values={{ selectedUserGroupCount: this.state.selectedUserGroups.length }}
                />
              </Text>
            )}
          </Flex>
          <span className="EditCompartment__tabSection--rightAligned">
            <Flex alignItems="center">
              <span className="EditCompartment__listCountDisplay">
                {this.state.displayLoadingForUserGroupCounts ? (
                  <Wait size="S" />
                ) : (
                  <FormattedMessage
                    id="EditCompartment.UserGroupTableCountDisplay"
                    description="Display current user group count and total user group count"
                    defaultMessage="Displaying {currentUserGroupCount} of {totalUserGroupCount} {userGroupLabel}"
                    values={{
                      currentUserGroupCount: this.state.filteredUserGroups.length,
                      totalUserGroupCount: finalTotalUserGroups,
                      userGroupLabel:
                        finalTotalUserGroups === 1
                          ? formatMessage(messages.UserGroupsLableSingular)
                          : formatMessage(messages.UserGroupsLablePlural),
                    }}
                  />
                )}
              </span>
              {!readOnlyMode && (
                <>
                  {this.props.isUserGroupSharingEnabled && hasSharableGroups && (
                    <Flex marginX={'size-150'} alignItems={'center'}>
                      <DialogTrigger type="modal">
                        <ButtonV3
                          variant="primary"
                          data-testid="user-group-table-share-user-groups"
                          isDisabled={isLeafOrg || this.state.selectedUserGroups.length === 0}
                        >
                          <FormattedMessage
                            id="EditCompartment.UserGroups.ShareUserGroups"
                            defaultMessage="Share user group"
                          />
                        </ButtonV3>
                        <CreateUserGroupShareDialog
                          rootOrgId={this.props.selectedOrg.id}
                          selectedUserGroups={this.state.selectedUserGroups}
                          onCreateUserGroupShares={(groups, orgs, overwriteStrategy, apiResponse) => {
                            this.createJob(groups, orgs, overwriteStrategy, apiResponse);
                          }}
                        />
                      </DialogTrigger>
                      {(!userGroupsSelected || isLeafOrg) && (
                        <ContextualHelp content={this.getDisabledReason(this.props.selectedOrg)} />
                      )}
                    </Flex>
                  )}
                  <ModalTrigger>
                    <Button
                      variant="primary"
                      className="EditCompartment_pageButton"
                      data-testid="user-group-table-add-user-group"
                      onClick={(): void => Analytics.fireCTAEvent('add user group dialog opened')}
                      disabled={isAddDisabled}
                    >
                      <FormattedMessage id="EditCompartment.UserGroups.AddUserGroup" defaultMessage="Add User Group" />
                    </Button>
                    <UserGroupDialogIntl
                      {...this.props}
                      userGroup={UUserGroup.getNewUserGroup(this.props.selectedOrg.organization.id)}
                      update={this.props.update}
                      context={EditUserGroupDialogContext.ADD}
                      selectedOrg={this.props.selectedOrg}
                    />
                  </ModalTrigger>
                </>
              )}
              <GoHelpBubble goUrlKey={GoUrlKeys.organizationsUserGroups}>
                <p>{formatMessage(messages.PaneHelp)}</p>
              </GoHelpBubble>
            </Flex>
          </span>
        </div>
        <SearchError showError={this.state.showSearchError} />
        <ScrollableContent
          onScroll={this.loadNextPage}
          enableInfiniteScroll={
            this.props.selectedOrg.totalUserGroupPageCount > 1 && this.state.searchInput.length === 0
          }
          uniqueId="EditCompartment_UserGroupTableID"
          className="EditCompartment_tabContent"
          hasMorePages={this.morePagesToLoad()}
        >
          <React.Fragment>
            <UserGroupsTable
              {...this.props}
              filteredUserGroups={this.getFilteredUserGroups(this.props.selectedOrg)}
              formatMessage={formatMessage}
              onIsCheckboxesSelected={(isSelected) => {
                this.setState({ isCheckboxesSelected: isSelected });
              }}
              onSelectedUserGroups={(userGroups) => {
                this.setState({ selectedUserGroups: userGroups });
              }}
              isUserGroupSharingEnabled={this.props.isUserGroupSharingEnabled}
              logsList={this.state.logsList}
            />
            {this.state.loadingSearchResults && <Wait className="Load_wait" />}
          </React.Fragment>
        </ScrollableContent>
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default injectIntl(UserGroupsTab);
