/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';
import config from '../configurations/config';
import Constants from '../constants/Constants';

export class UserGroupSharingErrorProvider {
  static async fetchErrorLogsWithRetry(orgId: string) {
    const url = `${config.reportBroker.url}`;
    const requestBody = {
      endDate: new Date().toISOString().slice(0, -1) + '+00:00',
      startDate: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().slice(0, -1) + '+00:00',
      organization: [orgId],
      eventType: ['GROUP_SHARE_ERROR'],
      page: {
        pageSize: 20,
        sort: 'date',
        sortOrder: 'DESC',
      },
      parentOrgId: orgId,
    };

    for (let i = 0; i < Constants.GROUP_PROJECTION_ERROR_STATUS_API_RETRY_COUNT; i++) {
      try {
        const response = await fetch(`${url}/reportbroker/insights/auditLogsMultiOrgCol/views/view`, {
          method: 'POST',
          headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
          body: JSON.stringify(requestBody),
        });

        const statusCode = response.status;
        if (statusCode < 200 || statusCode >= 300) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (i === Constants.GROUP_PROJECTION_ERROR_STATUS_API_RETRY_COUNT - 1) {
          return [];
        }
        await new Promise((resolve) => setTimeout(resolve, Constants.GROUP_PROJECTION_ERROR_STATUS_API_RETRY_INTERVAL));
      }
    }
  }
}
