import {
  Cell,
  Column,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  Key,
  Row,
  SearchField,
  Selection,
  TableBody,
  TableHeader,
  TableView,
  Text,
  useListData,
  StatusLight,
  ContextualHelp,
  Footer,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { RowData, SelectOrganizationsStepProps } from '../types';
import { ManageUserGroupShareContentModel, useManageUserGroupShareContent } from '../ContentModels';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Utils from '../../../services/utils/Utils';
import HierarchyManager from '../../../services/organization/HierarchyManager';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useRevokeContext } from '../contexts/RevokeContext';
import { Link } from 'react-router-dom';
import FloodgateService from '../../../services/floodgate/FloodgateService';

export const SelectOrganizationsStep = ({
  sourceOrgUserGroup,
  content,
  initSelectedKeys,
  disabled,
  logsList,
}: SelectOrganizationsStepProps) => {
  const contentEntry = content ?? useManageUserGroupShareContent();
  const { toggleDisableNextButton } = useNavigationContext();
  const { persistSelectedOrgUserGroups } = useRevokeContext();

  const emptyTableHeight: string = 'size-3400';
  const userGroupName: string | undefined = sourceOrgUserGroup?.name || undefined;
  const userGroupOwner: string | undefined = HierarchyManager.getOrg(sourceOrgUserGroup?.orgId)?.name || undefined;

  const [searchTerm, setSearchTerm] = useState<string>('');

  const allData: RowData[] = useMemo(() => {
    const targetGroupIdToOrgPath: Map<string, string> = Utils.createTargetGroupIdToOrgPathMap(sourceOrgUserGroup);
    let renderData: RowData[] = [];
    targetGroupIdToOrgPath.forEach((value, key): void => {
      renderData.push({ id: key, orgPath: value });
    });
    return renderData;
  }, [sourceOrgUserGroup]);

  let list = useListData({
    initialItems: allData,
    initialSelectedKeys: new Set<Key>(initSelectedKeys),
    getKey: (item: RowData): string => item.id,
    filter: (item: RowData, filterText: string): boolean =>
      item.orgPath.toLowerCase().includes(filterText.trim().toLowerCase()),
  });

  const getLogCount = (listOfLogs: any[], id: string) => {
    return listOfLogs.filter((log) => log.data.event.targetGroupId && log.data.event.targetGroupId === id).length;
  };

  const handleSearch = useCallback(
    (term: string): void => {
      setSearchTerm(term);
      if (term.length > 0) {
        list.setFilterText(term);
      } else {
        list.setFilterText('');
      }
    },
    [list.filterText, allData]
  );

  useEffect(() => {
    if (list.selectedKeys instanceof Set && list.selectedKeys.size === 0) {
      toggleDisableNextButton(true);
    }
  }, []);

  useEffect(() => {
    persistSelectedOrgUserGroups(list.selectedKeys);
  }, [list.selectedKeys]);

  const handleKeys = (keys: Selection) => {
    if (keys instanceof Set) {
      if (keys.size > 0) {
        toggleDisableNextButton(false);
      } else if (keys.size === 0) {
        toggleDisableNextButton(true);
      }
    }
    list.setSelectedKeys(keys);

    if (typeof keys === 'string' && keys === 'all' && !disabled) {
      const allSelections: Selection = new Set(list.items.flatMap((dataRow: RowData) => dataRow.id));
      list.setSelectedKeys(allSelections);
      toggleDisableNextButton(false);
    }
  };

  const renderEmptyTableState = () => {
    return (
      <IllustratedMessage>
        <NotFound />
        <Heading>No results</Heading>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  };

  return (
    <>
      <Flex direction="row" height="size-400" gap="size-200">
        {userGroupName && (
          <Flex data-testid="user-group-name-description" direction="row" gap="size-40">
            <Text>{contentEntry.get(ManageUserGroupShareContentModel.selectUserGroupNameDescription)} </Text>
            <Text>
              <b>{userGroupName}</b>
            </Text>
          </Flex>
        )}
        {userGroupOwner && (
          <Flex data-testid="user-group-owner-description" direction="row" gap="size-40">
            <Text>{contentEntry.get(ManageUserGroupShareContentModel.selectOwnedByDescription)}</Text>
            <Text>
              <b>{userGroupOwner}</b>
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex direction="column" gap="size-200">
        <Text> {contentEntry.get(ManageUserGroupShareContentModel.selectDescription)} </Text>
        <SearchField
          aria-label={`${contentEntry.get(ManageUserGroupShareContentModel.search)}`}
          onClear={() => setSearchTerm('')}
          onChange={handleSearch}
          value={searchTerm}
          data-testid="table-search"
        />
        {FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) && (
          <TableView
            selectedKeys={list.selectedKeys}
            disabledKeys={disabled ? Array.from(list.items.values()).map((value) => value.id) : []}
            onSelectionChange={handleKeys}
            aria-label={`${contentEntry.get(ManageUserGroupShareContentModel.selectTableLabel)}`}
            data-testid="shared-user-groups-with-target-orgs-table"
            selectionMode="multiple"
            renderEmptyState={renderEmptyTableState}
            // Height needs to be defined to show the empty table IllustratedMessage
            height={list.items.length === 0 ? emptyTableHeight : undefined}
          >
            <TableHeader>
              <Column key="orgPath">{`${contentEntry.get(
                ManageUserGroupShareContentModel.selectTableColumnName
              )}`}</Column>
              <Column key="syncStatus">SYNC STATUS</Column>
            </TableHeader>
            <TableBody items={list.items}>
              {(item) => (
                <Row key={item.id}>
                  <Cell>{item.orgPath}</Cell>
                  <Cell>
                    {getLogCount(logsList, item.id) === 0 ? (
                      <StatusLight variant="neutral">No issues found</StatusLight>
                    ) : (
                      <Flex direction="row" gap="size-50" alignItems="center">
                        <StatusLight variant="notice">Sync issues found</StatusLight>
                        <ContextualHelp variant="info">
                          <Heading>Sync status for past 24 hours</Heading>
                          <Content>
                            <Text>{getLogCount(logsList, item.id)} sync issues found</Text>
                          </Content>
                          <Footer>
                            <Link to="/insights">View audit log</Link>
                          </Footer>
                        </ContextualHelp>
                      </Flex>
                    )}
                  </Cell>
                </Row>
              )}
            </TableBody>
          </TableView>
        )}
        {!FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) && (
          <TableView
            selectedKeys={list.selectedKeys}
            disabledKeys={disabled ? Array.from(list.items.values()).map((value) => value.id) : []}
            onSelectionChange={handleKeys}
            aria-label={`${contentEntry.get(ManageUserGroupShareContentModel.selectTableLabel)}`}
            data-testid="shared-user-groups-with-target-orgs-table"
            selectionMode="multiple"
            renderEmptyState={renderEmptyTableState}
            // Height needs to be defined to show the empty table IllustratedMessage
            height={list.items.length === 0 ? emptyTableHeight : undefined}
          >
            <TableHeader>
              <Column key="orgPath">{`${contentEntry.get(
                ManageUserGroupShareContentModel.selectTableColumnName
              )}`}</Column>
            </TableHeader>
            <TableBody items={list.items}>
              {(item) => <Row key={item.id}>{(columnKey) => <Cell>{item[columnKey as keyof typeof item]}</Cell>}</Row>}
            </TableBody>
          </TableView>
        )}
      </Flex>
    </>
  );
};
