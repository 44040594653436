/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Footer,
  Heading,
  InlineAlert,
  ProgressCircle,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { ReviewSelectedUserGroupsView } from './ReviewSelectedUserGroupsView';
import { OverwriteUserGroupsView } from './OverwriteUserGroupsView';
import { useCallback, useEffect, useState } from 'react';
import { OrganizationSelector, OrganizationSelectorContentModel } from '@pandora/react-insights';
import { isEmpty } from 'lodash';
import { buildOrgHierarchy } from './buildOrgHierarchy';
import Analytics from '../../Analytics/Analytics';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { CreateUserGroupShareContentModel, useCreateUserGroupShareContent } from './ContentModels';
import { CreateUserGroupShareDialogProps } from './types';
import { useLogger } from '@pandora/react-logger-provider';
import HierarchyManager from '../../services/organization/HierarchyManager';
import FloodgateService from '../../services/floodgate/FloodgateService';
// eslint-disable-next-line import/no-named-as-default
import LoadOrgDataService from '../../services/orgMaster/LoadOrgDataService';
import BanyanCompartmentAPI from '../../providers/BanyanCompartmentAPI';
import './OverwriteUserGroupsView.css';

enum Page {
  REVIEW_USER_GROUPS,
  SELECT_ORGANIZATIONS,
  CHECK_CONFLICTS,
  OVERWRITE_USER_GROUPS,
}

export const CreateUserGroupShareDialog = ({
  rootOrgId,
  selectedUserGroups,
  defaultOrgSelections = [],
  onCreateUserGroupShares,
  content,
  ...others
}: CreateUserGroupShareDialogProps) => {
  const dialogContainer = useDialogContainer();
  const logger = useLogger();
  const [orgHierarchy, setOrgHierarchy] = useState<TreeNode | undefined>();
  const [showError, setShowError] = useState(false);
  const [showApiError, setShowApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [page, setPage] = useState<Page>(Page.REVIEW_USER_GROUPS);
  const [targetOrgIds, setTargetOrgIds] = useState(defaultOrgSelections);
  const contentEntry = content ?? useCreateUserGroupShareContent(targetOrgIds.length);
  const selectorContentEntry = contentEntry.search(OrganizationSelectorContentModel)[0];
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [timeoutReached, setTimeoutReached] = useState(false); // Timeout state
  const [apiResponse, setApiResponse] = useState<any>(null);
  const [strategy, setStrategy] = useState<string>('IGNORE');

  useEffect(() => {
    const abortController = new AbortController();
    async function loadData() {
      try {
        setShowError(false);
        const orgData = HierarchyManager.getOrgs();
        const promises = [];

        for (const uOrg of orgData) {
          promises.push(LoadOrgDataService.loadUserGroups(uOrg.id, true, abortController.signal));
        }

        await Promise.all(promises);

        if (!abortController.signal.aborted) {
          setOrgHierarchy(buildOrgHierarchy(rootOrgId, orgData));
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          logger.error('Error loading org hierarchy', e);
          setShowError(true);
        }
      }
    }
    void loadData();
    return () => {
      abortController.abort();
    };
  }, [rootOrgId]);

  const checkUserGroupConflicts = useCallback(async () => {
    try {
      setIsLoading(true);
      setTimeoutReached(false);

      const timeout = setTimeout(() => {
        setTimeoutReached(true);
        setIsLoading(false);
        logger.error('API call timed out');
        setPage(Page.OVERWRITE_USER_GROUPS);
      }, 120000);

      const response = await BanyanCompartmentAPI.getUserGroupsConflicts(rootOrgId, targetOrgIds, selectedUserGroups);
      clearTimeout(timeout);

      setIsLoading(false);
      setApiResponse(response);
    } catch (error) {
      setIsLoading(false);
      setShowApiError(true);
      setApiErrorMessage(error.message);
      logger.error('Error during API call', error);
    }
  }, [targetOrgIds]);

  const handleNext = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog switched to next page');
    setPage(Page.SELECT_ORGANIZATIONS);
  }, [dialogContainer]);

  const handlePrevious = useCallback(
    (previous: Page) => {
      Analytics.fireCTAEvent('create user group share dialog switched to previous page');
      setPage(previous);
    },
    [dialogContainer]
  );

  const handleCancel = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog cancelled');
    dialogContainer.dismiss();
  }, [dialogContainer]);

  const handleCommit = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog confirmed');
    logger.info(`Sharing ${selectedUserGroups.map((ug) => ug.id).join(', ')} with ${targetOrgIds.join(', ')}`);
    onCreateUserGroupShares(selectedUserGroups, targetOrgIds, strategy, apiResponse);
    dialogContainer.dismiss();
  }, [targetOrgIds, selectedUserGroups, dialogContainer, strategy]);

  const handleConflictCheck = useCallback(() => {
    Analytics.fireCTAEvent('select user group share switched to conflict checker page');
    setPage(Page.CHECK_CONFLICTS);
    checkUserGroupConflicts();
  }, [dialogContainer]);

  const handleConflict = useCallback(() => {
    setPage(Page.OVERWRITE_USER_GROUPS);
  }, [dialogContainer]);

  var heading;
  switch (page) {
    case Page.REVIEW_USER_GROUPS:
      heading = contentEntry.get(CreateUserGroupShareContentModel.reviewHeader);
      break;
    case Page.SELECT_ORGANIZATIONS:
      heading = contentEntry.get(CreateUserGroupShareContentModel.selectHeader);
      break;
    case Page.CHECK_CONFLICTS:
      heading = contentEntry.get(CreateUserGroupShareContentModel.conflictCheckHeader);
      break;
    case Page.OVERWRITE_USER_GROUPS:
      heading = contentEntry.get(CreateUserGroupShareContentModel.overwriteHeader);
      break;
  }

  return (
    <Dialog size={'L'} role="dialog" {...others} width={'860px'}>
      <Heading data-testid={'modal-header'}>{heading}</Heading>
      <Divider />
      <Content data-testid={'modal-content'} minHeight={'size-6000'}>
        {showError && (
          <InlineAlert variant="negative" data-testid="error-alert">
            {contentEntry.get(CreateUserGroupShareContentModel.error)}
          </InlineAlert>
        )}
        {showApiError && (
          <InlineAlert variant="negative" data-testid="error-api-alert">
            {apiErrorMessage}
          </InlineAlert>
        )}
        {page === Page.REVIEW_USER_GROUPS && (
          <ReviewSelectedUserGroupsView selectedUserGroups={selectedUserGroups} hideHeading={true} />
        )}
        {page === Page.SELECT_ORGANIZATIONS && (
          <>
            <p>{contentEntry.get(CreateUserGroupShareContentModel.selectDescription)}</p>
            {!orgHierarchy && (
              <ProgressCircle
                size="M"
                isIndeterminate
                top="50%"
                left="50%"
                data-testid={'modal-wait'}
                aria-label={`${contentEntry.get(CreateUserGroupShareContentModel.loadingAriaLabel)}`}
              />
            )}
            {orgHierarchy && (
              <OrganizationSelector
                defaultSelections={targetOrgIds}
                orgHierarchy={orgHierarchy!}
                onSelectionChanged={setTargetOrgIds}
                selectableDescendantsOrgId={rootOrgId}
                content={selectorContentEntry}
              />
            )}
          </>
        )}
        {page === Page.CHECK_CONFLICTS && (
          <>
            {isLoading && (
              <>
                <div className="container text">
                  <p>{contentEntry.get(CreateUserGroupShareContentModel.conflictDescription)}</p>
                  <br />
                  <br />
                </div>
                <ProgressCircle
                  size="L"
                  isIndeterminate
                  top="50%"
                  left="50%"
                  data-testid={'conflict-check-modal-wait'}
                  position="absolute"
                  aria-label={`${contentEntry.get(CreateUserGroupShareContentModel.loadingAriaLabel)}`}
                />
              </>
            )}
          </>
        )}
        {page === Page.OVERWRITE_USER_GROUPS && (
          <OverwriteUserGroupsView
            apiResponse={apiResponse}
            timeoutReached={timeoutReached}
            setStrategy={setStrategy}
            userGroups={selectedUserGroups}
            rootOrgId={rootOrgId}
            targetOrgIds={targetOrgIds}
          />
        )}
      </Content>
      {page === Page.SELECT_ORGANIZATIONS && (
        <Footer>
          <Button
            variant="secondary"
            data-testid="previous-button"
            onPress={() => handlePrevious(Page.REVIEW_USER_GROUPS)}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.previousLabel)}
          </Button>
        </Footer>
      )}
      {page === Page.CHECK_CONFLICTS && (
        <Footer>
          <Button
            variant="secondary"
            data-testid="check-conflict-previous-button"
            onPress={() => handlePrevious(Page.SELECT_ORGANIZATIONS)}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.previousLabel)}
          </Button>
        </Footer>
      )}
      {page === Page.OVERWRITE_USER_GROUPS && (
        <Footer>
          <Button
            variant="secondary"
            data-testid="overwrite-previous-button"
            onPress={() => handlePrevious(Page.CHECK_CONFLICTS)}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.previousLabel)}
          </Button>
        </Footer>
      )}
      <ButtonGroup>
        <Button variant="secondary" data-testid="cancel-button" onPress={handleCancel}>
          {contentEntry.get(CreateUserGroupShareContentModel.cancelLabel)}
        </Button>
        {page === Page.REVIEW_USER_GROUPS && (
          <Button
            variant="accent"
            data-testid="next-button"
            onPress={handleNext}
            isDisabled={isEmpty(selectedUserGroups)}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.nextLabel)}
          </Button>
        )}
        {page === Page.SELECT_ORGANIZATIONS &&
          !FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) && (
            <Button
              variant="accent"
              data-testid="create-share"
              onPress={handleCommit}
              isDisabled={isEmpty(targetOrgIds)}
            >
              {contentEntry.get(CreateUserGroupShareContentModel.ctaLabel)}
            </Button>
          )}
        {page === Page.SELECT_ORGANIZATIONS &&
          FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) && (
            <Button
              variant="accent"
              data-testid="org-select-next-button"
              onPress={handleConflictCheck}
              isDisabled={isEmpty(targetOrgIds)}
            >
              {contentEntry.get(CreateUserGroupShareContentModel.nextLabel)}
            </Button>
          )}
        {page === Page.CHECK_CONFLICTS && (
          <Button
            variant="accent"
            data-testid="conflict-check-next-button"
            onPress={handleConflict}
            isDisabled={isLoading}
            isHidden={showApiError}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.nextLabel)}
          </Button>
        )}

        {page === Page.OVERWRITE_USER_GROUPS && (
          <Button variant="accent" data-testid="overwrite-user-groups" onPress={handleCommit} isHidden={timeoutReached}>
            {contentEntry.get(CreateUserGroupShareContentModel.ctaLabel)}
          </Button>
        )}
      </ButtonGroup>
    </Dialog>
  );
};
