/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { defineMessages, useIntl } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { OverwriteUserGroupsViewProps } from './types';
import { ReactComponent as AlertIcon } from '@a4u/a4u-s_illustrations/assets/S_Illu_Linear_SVG_200/S_illu_Alert_blueAccent.svg';
import { ReactComponent as CheckMark } from '@a4u/a4u-s_illustrations/assets/S_Illu_Linear_SVG_200/S_illu_checkmarkCircle_blueAccent.svg';
import IconWithMessage from './IconWithMessage';
import Analytics from '../../Analytics/Analytics';
import './OverwriteUserGroupsView.css';
import {
  Cell,
  Column,
  Flex,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  Link,
  RadioGroup,
  Radio,
  TooltipTrigger,
  Tooltip,
  ActionButton,
} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import Download from '../../services/utils/Download';
import BanyanCompartmentAPI from '../../providers/BanyanCompartmentAPI';

const messages = defineMessages({
  Heading: {
    id: 'OverwritedUserGroupsView.Heading',
    defaultMessage: 'Existing user group merge controls',
  },
  TimeOut: {
    id: 'OverwriteUserGroupsView.TimeOut',
    defaultMessage: 'Sorry the operation timed out. Please try again with fewer organizations or groups selected',
  },
  NoConflict: {
    id: 'OverwriteUserGroupsView.NoConflict',
    defaultMessage: 'Success. No user group conflicts found.',
  },
  Intro: {
    id: 'OverwriteUserGroupsView.Intro',
    defaultMessage: ' user group conflicts found in the target organisations',
  },
  UserGroupNameHeading: {
    id: 'OverwriteUserGroupsView.TableHeading.UserGroup',
    defaultMessage: 'USER GROUP CONFLICTS',
  },
  TargetOrgHeading: {
    id: 'OverwriteUserGroupsView.TableHeading.TargetOrg',
    defaultMessage: 'TARGET ORGANIZATION',
  },
  OverWriteMessage: {
    id: 'OverwriteUserGroupsView.OverwriteMessage',
    defaultMessage: 'Please specify how to handle any user group merge conflicts.',
  },
  OverWriteIgnore: {
    id: 'OverwriteUserGroupsView.OverwriteOptions.Ignore',
    defaultMessage:
      'Ignore(default) - Existing groups in target orgs will be skipped. To avoid conflicts, these groups can be manually removed or renamed in admin console.',
  },
  OverWriteAddOn: {
    id: 'OverwriteUserGroupsView.OverwriteOptions.AddOn',
    defaultMessage: 'Add only - add new users to existing user groups',
  },
  OverwriteMirror: {
    id: 'OverwriteUserGroupsView.OverwriteOptions.Mirror',
    defaultMessage: 'Mirror group - add or remove from target orgs to match the shared group',
  },
  LearnMore: {
    id: 'OverwriteUserGroupsView.LearnMore',
    defaultMessage: 'Learn more about user group merge options.',
  },
  Note: {
    id: 'OverwriteUserGroupsView.Note',
    defaultMessage: 'Note: This group cannot be shared and will be ignored.',
  },
  CsvInfo: {
    id: 'OverwriteUserGroupsView.CsvInfo',
    defaultMessage: 'Displaying first 25 conflicts,',
  },
  CsvDownload: {
    id: 'OverwriteUserGroupsView.CsvDownload',
    defaultMessage: 'download CSV to view all',
  },
  ProjectionAlreadyExists: {
    id: 'OverwriteUserGroupsView.ProjectionAlreadyExists',
    defaultMessage: 'The projection for the source group already exists in the target org',
  },
  ProjectionRemovalInProgress: {
    id: 'OverwriteUserGroupsView.ProjectionRemovalInProgress',
    defaultMessage: 'The projection for the source group is currently being removed from the target org',
  },
  InvalidTargetOrg: {
    id: 'OverwriteUserGroupsView.InvalidTargetOrg',
    defaultMessage: 'The target org is not a descendant of the source org',
  },
  TargetGroupIsShared: {
    id: 'OverwriteUserGroupsView.TargetGroupIsShared',
    defaultMessage: 'The conflicting group is already shared FROM another org',
  },
  TargetGroupIsProjectedToOtherOrg: {
    id: 'OverwriteUserGroupsView.TargetGroupIsProjectedToOtherOrg',
    defaultMessage: 'The conflicting group is already shared TO another org',
  },
  TargetGroupNotAUserGroup: {
    id: 'OverwriteUserGroupsView.TargetGroupNotAUserGroup',
    defaultMessage: 'The conflicting group is not a user group',
  },
  TargetGroupNotTeamMember: {
    id: 'OverwriteUserGroupsView.TargetGroupNotTeamMember',
    defaultMessage: 'The conflicting group does not have a role of TEAM_MEMBER',
  },
  TargetGroupExternallyManaged: {
    id: 'OverwriteUserGroupsView.TargetGroupExternallyManaged',
    defaultMessage: 'The conflicting group is already synced from an external source like Azure/Google',
  },
  TargetGroupRecentlyTakenOver: {
    id: 'OverwriteUserGroupsView.TargetGroupRecentlyTakenOver',
    defaultMessage:
      'The conflicting group was recently taken over and the taking over of said group is paused for a period',
  },
});

enum Page {
  TIMEOUT,
  NO_CONFLICT,
  CONFLICT,
}

export const OverwriteUserGroupsView: React.FC<OverwriteUserGroupsViewProps> = ({
  apiResponse,
  timeoutReached,
  setStrategy,
  userGroups,
  rootOrgId,
  targetOrgIds,
}) => {
  const intl = useIntl();
  const [page, setPage] = useState<Page>();

  useEffect(() => {
    if (timeoutReached) {
      Analytics.fireCTAEvent('conflict preview API timed out');
      setPage(Page.TIMEOUT);
    } else if (!apiResponse || (apiResponse && apiResponse.totalConflictGroups == 0)) {
      Analytics.fireCTAEvent('no user group conflicts found');
      setPage(Page.NO_CONFLICT);
    } else {
      Analytics.fireCTAEvent('user group conflicts found');
      setPage(Page.CONFLICT);
    }
  }, [timeoutReached, apiResponse]);

  const handleStrategyChange = (value: string) => {
    setStrategy(value);
  };

  const handleDownloadCSV = async () => {
    try {
      const link = await BanyanCompartmentAPI.getUserGroupsConflictsCSV(rootOrgId, targetOrgIds, userGroups);
      Download.download(link, '');
    } catch (error) {
      console.error('Error fetching CSV link:', error);
    }
  };

  const conflictDetails = apiResponse?.conflictDetails.slice(0, 25);

  const errorCodeMap: { [key: string]: string } = {
    PROJECTION_ALREADY_EXISTS: intl.formatMessage(messages.ProjectionAlreadyExists),
    PROJECTION_REMOVAL_IN_PROGRESS: intl.formatMessage(messages.ProjectionRemovalInProgress),
    INVALID_TARGET_ORG: intl.formatMessage(messages.InvalidTargetOrg),
    TARGET_GROUP_IS_SHARED: intl.formatMessage(messages.TargetGroupIsShared),
    TARGET_GROUP_IS_PROJECTED_TO_OTHER_ORG: intl.formatMessage(messages.TargetGroupIsProjectedToOtherOrg),
    TARGET_GROUP_NOT_A_USER_GROUP: intl.formatMessage(messages.TargetGroupNotAUserGroup),
    TARGET_GROUP_NOT_TEAM_MEMBER: intl.formatMessage(messages.TargetGroupNotTeamMember),
    TARGET_GROUP_EXTERNALLY_MANAGED: intl.formatMessage(messages.TargetGroupExternallyManaged),
    TARGET_GROUP_RECENTLY_TAKEN_OVER: intl.formatMessage(messages.TargetGroupRecentlyTakenOver),
  };

  return (
    <>
      <div data-testid={'modal-content'} className={page === Page.CONFLICT ? '' : 'container'}>
        {page === Page.TIMEOUT && (
          <IconWithMessage message={messages.TimeOut.defaultMessage} icon={<AlertIcon />} size={100}></IconWithMessage>
        )}
        {page === Page.NO_CONFLICT && (
          <IconWithMessage
            message={messages.NoConflict.defaultMessage}
            icon={<CheckMark />}
            size={100}
          ></IconWithMessage>
        )}
        {page === Page.CONFLICT && (
          <>
            <p>
              {apiResponse.totalConflictGroups}
              {intl.formatMessage(messages.Intro)}
            </p>
            {
              <Flex width="100%" direction="column" gap="size-50">
                <TableView height="size-3000">
                  <TableHeader>
                    <Column width={'50%'}>{messages.TargetOrgHeading.defaultMessage}</Column>
                    <Column width={'50%'}>{intl.formatMessage(messages.UserGroupNameHeading)}</Column>
                  </TableHeader>
                  <TableBody items={conflictDetails}>
                    {(item) => (
                      <Row key={item.userGroupId + item.organizationId}>
                        <Cell>
                          <Flex alignItems="center">{item.organizationName}</Flex>
                        </Cell>
                        <Cell>
                          <Flex alignItems="center">
                            {item.userGroupName}
                            {!item.mergeAllowed && (
                              <TooltipTrigger placement="right">
                                <ActionButton isQuiet>
                                  <Alert size="S" aria-label="Merge not allowed" />
                                </ActionButton>
                                <Tooltip>
                                  {intl.formatMessage(messages.Note)} {item.errorCode && errorCodeMap[item.errorCode]}
                                </Tooltip>
                              </TooltipTrigger>
                            )}
                          </Flex>
                        </Cell>
                      </Row>
                    )}
                  </TableBody>
                </TableView>
                <Text alignSelf="end">
                  {intl.formatMessage(messages.CsvInfo)}{' '}
                  <Link onPress={handleDownloadCSV}>{intl.formatMessage(messages.CsvDownload)}</Link>
                </Text>
              </Flex>
            }
            <Flex gap="size-150" direction="column">
              <Text>
                {intl.formatMessage(messages.OverWriteMessage)}{' '}
                <Link
                  href="https://helpx.adobe.com/enterprise/global-admin-console/manage-user-groups.html#share-groups"
                  target="_blank"
                >
                  {intl.formatMessage(messages.LearnMore)}
                </Link>
              </Text>
              <RadioGroup aria-label="User group merge conflicts" defaultValue="IGNORE" onChange={handleStrategyChange}>
                <Radio value="IGNORE" aria-label="Overwrite option 1">
                  {intl.formatMessage(messages.OverWriteIgnore)}
                </Radio>
                <Radio value="ADD_ON" aria-label="Overwrite option 2">
                  {intl.formatMessage(messages.OverWriteAddOn)}
                </Radio>
                <Radio value="MIRROR" aria-label="Overwrite option 3">
                  {intl.formatMessage(messages.OverwriteMirror)}
                </Radio>
              </RadioGroup>
            </Flex>
          </>
        )}
      </div>
    </>
  );
};
